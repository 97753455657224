import React from 'react';
import { Card, CardHeader, CardTitle, CardContent } from './ui/card';
import { Tabs, TabsList, TabsTrigger, TabsContent } from './ui/tabs';
import { Alert, AlertDescription } from './ui/alert';
import { api } from '../services/api';

const PersonalityAnalyzer = ({ userData }) => {
  if (!userData?.personalityInsights) {
    return (
      <Card>
        <CardContent className="p-6">
          <Alert>
            <AlertDescription className="text-center text-gray-500">
              Personality analysis not available. Please try refreshing your data.
            </AlertDescription>
          </Alert>
        </CardContent>
      </Card>
    );
  }

  const {
    coreTraits = {},
    professional = {},
    relationships = {},
    behavioralInsights = {},
    contextualFactors = {}
  } = userData.personalityInsights;

  const renderSection = (data, title) => {
    if (!data) return null;
    
    const contentToRender = typeof data === 'string' 
      ? { summary: data }
      : data;

    return (
      <Card className="mb-4">
        <CardHeader>
          <CardTitle className="text-lg font-semibold text-gray-900">{title}</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            {Object.entries(contentToRender).map(([key, value]) => {
              if (!value) return null;
              
              if (typeof value === 'object') {
                return (
                  <div key={key} className="space-y-2">
                    <h4 className="text-sm font-medium text-gray-600 capitalize">
                      {key.replace(/([A-Z])/g, ' $1').trim()}
                    </h4>
                    <div className="pl-4">
                      {Object.entries(value).map(([subKey, subValue]) => (
                        <div key={subKey} className="mb-2">
                          <h5 className="text-sm font-medium text-gray-500 capitalize">
                            {subKey.replace(/([A-Z])/g, ' $1').trim()}
                          </h5>
                          <p className="text-sm text-gray-700 leading-relaxed">{subValue}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                );
              }

              return (
                <div key={key} className="space-y-2">
                  <h4 className="text-sm font-medium text-gray-600 capitalize">
                    {key === 'summary' ? '' : key.replace(/([A-Z])/g, ' $1').trim()}
                  </h4>
                  <p className="text-sm text-gray-700 leading-relaxed">{value}</p>
                </div>
              );
            })}
          </div>
        </CardContent>
      </Card>
    );
  };

  return (
    <div className="space-y-6">
      <Tabs defaultValue="core" className="space-y-6">
        <TabsList className="grid grid-cols-2 md:grid-cols-5 w-full">
          <TabsTrigger value="core">Core Traits</TabsTrigger>
          <TabsTrigger value="professional">Professional</TabsTrigger>
          <TabsTrigger value="relationships">Relationships</TabsTrigger>
          <TabsTrigger value="behavioral">Behavioral</TabsTrigger>
          <TabsTrigger value="context">Context</TabsTrigger>
        </TabsList>

        <TabsContent value="core">
          {renderSection(coreTraits, 'Core Personality Traits')}
        </TabsContent>

        <TabsContent value="professional">
          <div className="space-y-6">
            {renderSection(professional.workCapabilities, 'Work Capabilities')}
            {renderSection(professional.careerPatterns, 'Career Patterns')}
          </div>
        </TabsContent>

        <TabsContent value="relationships">
          <div className="space-y-6">
            {renderSection(relationships.interactionStyle, 'Interaction Style')}
            {renderSection(relationships.socialTendencies, 'Social Tendencies')}
            {renderSection(relationships.genderDynamics, 'Gender Dynamics')}
          </div>
        </TabsContent>

        <TabsContent value="behavioral">
          <div className="space-y-6">
            {renderSection(behavioralInsights.defensePatterns, 'Defense Patterns')}
            {renderSection(behavioralInsights.cognitivePatterns, 'Cognitive Patterns')}
            {renderSection(behavioralInsights.personalityType, 'Personality Type')}
          </div>
        </TabsContent>

        <TabsContent value="context">
          <div className="space-y-6">
            {renderSection({
              backgroundEffects: contextualFactors.backgroundEffects,
              knowledgeLimits: contextualFactors.knowledgeLimits,
              culturalBlindspots: contextualFactors.culturalBlindspots,
              developmentalNeeds: contextualFactors.developmentalNeeds
            }, 'Background & Development')}
            {renderSection({
              familyDynamics: contextualFactors.familyDynamics,
              culturalInfluences: contextualFactors.culturalInfluences,
              formativeExperiences: contextualFactors.formativeExperiences
            }, 'Cultural & Family Context')}
          </div>
        </TabsContent>
      </Tabs>

      {/* Debug Information */}
      <Card className="mt-8 bg-gray-50">
        <CardHeader>
          <CardTitle>Debug Information</CardTitle>
        </CardHeader>
        <CardContent>
          <pre className="text-xs overflow-auto">
            {JSON.stringify({
              hasPersonalityInsights: !!userData.personalityInsights,
              availableKeys: Object.keys(userData.personalityInsights),
              dataStructure: {
                hasCoreTraits: !!coreTraits,
                hasProfessional: !!professional,
                hasRelationships: !!relationships,
                hasBehavioralInsights: !!behavioralInsights,
                hasContextualFactors: !!contextualFactors
              }
            }, null, 2)}
          </pre>
        </CardContent>
      </Card>
    </div>
  );
};

export default PersonalityAnalyzer;