import React, { useEffect, useState, useRef } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Card, CardContent } from './ui/card';
import { Progress } from './ui/progress';
import { authService } from '../services/authService';

const RedditOAuthCallback = ({ setUserData, setIsLoggedIn, setError }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [processingStage, setProcessingStage] = useState('initializing');
  const [progress, setProgress] = useState(0);
  const hasProcessed = useRef(false);

  const updateProgress = (stage) => {
    const progressMap = {
      initializing: 0,
      connecting: 25,
      processing: 50,
      completing: 90,
      done: 100
    };
    setProgress(progressMap[stage] || 0);
    setProcessingStage(stage);
  };

  useEffect(() => {
    if (hasProcessed.current) return;
    hasProcessed.current = true;

    const code = searchParams.get('code');
    const state = searchParams.get('state');
    const storedState = sessionStorage.getItem('oauth_state');

    if (!code || state !== storedState) {
      setError('Invalid authentication request');
      navigate('/', { replace: true });
      return;
    }
      const handleOAuthCallback = async () => {
        try {
          updateProgress('connecting');
        
          // Check cache first using the code from URL
          const username = searchParams.get('username');
          if (username) {
            const cachedData = localStorage.getItem(`reddit_analysis_${username}`);
            if (cachedData) {
              const { data, timestamp, expiresIn } = JSON.parse(cachedData);
              if (Date.now() - timestamp < expiresIn) {
                updateProgress('completing');
                setUserData(data);
                setIsLoggedIn(true);
                updateProgress('done');
                sessionStorage.removeItem('oauth_state');
                navigate('/', { replace: true });
                return;
              }
            }
          }

          // If no cache or expired, proceed with full analysis
          const data = await authService.handleCallback(code);
        
          updateProgress('processing');
          console.log('Processing data:', {
            hasProfile: !!data.profile,
            postCount: data.posts?.length,
            hasAnalysis: !!data.contentAnalysis
          });

          // Cache the new analysis data
          localStorage.setItem(`reddit_analysis_${data.profile.name}`, JSON.stringify({
            data: data,
            timestamp: Date.now(),
            expiresIn: 30 * 24 * 60 * 60 * 1000 // 30 days
          }));

          updateProgress('completing');
          setUserData(data);
          setIsLoggedIn(true);
        
          updateProgress('done');
          sessionStorage.removeItem('oauth_state');
          navigate('/', { replace: true });
        } catch (err) {
          console.error('OAuth callback error:', err);
          setError(err.message);
          navigate('/', { replace: true });
        }
      };
        handleOAuthCallback();
  }, []);

  const message = {
    title: processingStage === 'done' ? 'Complete!' : 'Processing...',
    description: processingStage === 'done' 
      ? 'Redirecting to analysis...'
      : 'Analyzing your Reddit activity...'
  };

  return (
    <div className="min-h-screen bg-gray-100 p-8 flex items-center justify-center">
      <Card className="w-full max-w-md">
        <CardContent className="p-6">
          <div className="text-center">
            <h2 className="text-lg font-medium mb-2">{message.title}</h2>
            <p className="text-gray-600 mb-4">{message.description}</p>
            <Progress value={progress} className="w-full h-2" />
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default RedditOAuthCallback;
