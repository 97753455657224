import React, { useEffect } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from './ui/card';

import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
  BarChart, Bar
} from 'recharts';

import ReactMarkdown from 'react-markdown';

const AnalysisSection = ({ title, content }) => (
  <Card className="mb-4">
    <CardHeader>
      <CardTitle>{title}</CardTitle>
    </CardHeader>
    <CardContent>
      <ReactMarkdown className="prose">
        {content}
      </ReactMarkdown>
    </CardContent>
  </Card>
);
const EngagementOverTime = ({ posts }) => {
  if (!Array.isArray(posts) || posts.length === 0) {
    console.log('No posts data available for engagement chart');
    return null;
  }

  // Add debug logging
  console.log('Engagement data:', {
    postsCount: posts.length,
    samplePost: posts[0]?.data,
    dates: posts.map(p => new Date(p.data.created_utc * 1000))
  });

  const chartData = posts.reduce((acc, post) => {
    const date = new Date(post.data.created_utc * 1000);
    const monthKey = date.toISOString().slice(0, 7);

    if (!acc[monthKey]) {
      acc[monthKey] = {
        date: monthKey,
        engagement: 0,
        count: 0
      };
    }

    acc[monthKey].engagement += post.data.score || 0;
    acc[monthKey].count += 1;
    return acc;
  }, {});

  const formattedData = Object.values(chartData)
    .map(month => ({
      date: month.date,
      engagement: Math.round(month.engagement / month.count)
    }))
    .sort((a, b) => a.date.localeCompare(b.date));

  return (
    <div className="h-64">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={formattedData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis 
            dataKey="date" 
            tickFormatter={date => {
              const [year, month] = date.split('-');
              return `${month}/${year.slice(2)}`;
            }}
          />
          <YAxis />
          <Tooltip />
          <Line 
            type="monotone" 
            dataKey="engagement" 
            stroke="#2563eb" 
            strokeWidth={2}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

const ActivityPatterns = ({ data }) => {
  if (!data?.daily || !data?.hourly) return null;

  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const dailyData = data.daily.map((count, index) => ({
    day: daysOfWeek[index],
    posts: count
  }));

  const hourlyData = data.hourly.map((count, hour) => ({
    hour: `${hour.toString().padStart(2, '0')}:00`,
    posts: count
  }));

  return (
    <Card>
      <CardHeader>
        <CardTitle>Activity Patterns</CardTitle>
      </CardHeader>
      <CardContent className="space-y-6">
        <div>
          <h3 className="text-sm font-medium mb-3">Num. Posts & Comments by Day of Week</h3>
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={dailyData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="day" />
                <YAxis />
                <Tooltip formatter={(value) => [`${value} posts`, 'Activity']} />
                <Bar dataKey="posts" fill="#2563eb" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div>
          <h3 className="text-sm font-medium mb-3">Num. Posts & Comments by Hour of day</h3>
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={hourlyData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                  dataKey="hour" 
                  angle={-45} 
                  textAnchor="end" 
                  height={60}
                />
                <YAxis />
                <Tooltip formatter={(value) => [`${value} posts`, 'Activity']} />
                <Bar dataKey="posts" fill="#2563eb" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

const TopSubreddits = ({ subreddits }) => {
  if (!Array.isArray(subreddits) || subreddits.length === 0) return null;

  return (
    <div className="space-y-3">
      {subreddits.map((subreddit, index) => (
        <div 
          key={subreddit.name}
          className="flex items-center justify-between p-3 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors"
        >
          <div className="flex items-center gap-3">
            <span className="text-gray-500 font-medium">#{index + 1}</span>
            <span className="font-medium">r/{subreddit.name}</span>
          </div>
          <div className="flex items-center gap-4">
            <span className="text-sm text-gray-500">{subreddit.count} posts</span>
            <div className="w-20 bg-gray-200 rounded-full h-2">
              <div
                className="bg-blue-600 h-2 rounded-full"
                style={{ width: `${subreddit.percentage}%` }}
              />
            </div>
            <span className="text-sm font-medium">{subreddit.percentage}%</span>
          </div>
        </div>
      ))}
    </div>
  );
};

const ProfileAnalysis = ({ userData }) => {
  useEffect(() => {
    console.log('ProfileAnalysis data validation:', {
      hasProfile: userData?.profile,
      hasPosts: Array.isArray(userData?.posts),
      hasEngagement: userData?.engagement,
      hasActivityHeatmap: userData?.engagement?.activityHeatmap,
      hasTopSubreddits: Array.isArray(userData?.engagement?.topSubreddits)
    });
  }, [userData]);

  if (!userData) return null;

  return (
    <div className="space-y-8">
      {/* Rest of the component remains the same */}
      {/* Profile Summary */}
      <Card>
        <CardHeader>
          <CardTitle>Profile Summary</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <h3 className="font-medium">Username</h3>
              <p>{userData.profile?.name || userData.username}</p>
            </div>
            <div>
              <h3 className="font-medium">Karma</h3>
              <p>{userData.profile?.total_karma || userData.karma}</p>
            </div>
            {userData.engagement && (
              <>
                <div>
                  <h3 className="font-medium">Posts & Comments / Week</h3>
                  <p>{userData.engagement.postFrequency?.postsPerWeek || 'N/A'}</p>
                </div>
                <div>
                  <h3 className="font-medium">Average Upvote </h3>
                  <p>{Math.round(userData.engagement.averageScore || 0)}</p>
                </div>
              </>
            )}
          </div>
        </CardContent>
      </Card>

      {/* Engagement Over Time */}
      {userData.posts && (
        <Card>
          <CardHeader>
            <CardTitle>Engagement Over Time (upvotes) </CardTitle>
          </CardHeader>
          <CardContent>
            <EngagementOverTime posts={userData.posts} />
          </CardContent>
        </Card>
      )}

      {/* Content Analysis */}
      {userData.contentAnalysis && (
        <Card>
          <CardHeader>
            <CardTitle>Content Analysis</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-6">
              {/* Writing Style */}
              <div>
                <h3 className="text-lg font-semibold text-gray-900 mb-4">Writing Style</h3>
                <div className="space-y-4">
                  {Object.entries(JSON.parse(userData.contentAnalysis).writingStyle).map(([key, value]) => (
                    <div key={key} className="space-y-2">
                      <h4 className="text-sm font-medium text-gray-600 capitalize">
                        {key.replace(/([A-Z])/g, ' $1').trim()}
                      </h4>
                      <p className="text-sm text-gray-700 leading-relaxed">{value}</p>
                    </div>
                  ))}
                </div>
              </div>

              {/* Topics & Interests */}
              <div>
                <h3 className="text-lg font-semibold text-gray-900 mb-4">Topics & Interests</h3>
                <div className="space-y-4">
                  {Object.entries(JSON.parse(userData.contentAnalysis).topicsAndInterests).map(([key, value]) => (
                    <div key={key} className="space-y-2">
                      <h4 className="text-sm font-medium text-gray-600 capitalize">
                        {key.replace(/([A-Z])/g, ' $1').trim()}
                      </h4>
                      <p className="text-sm text-gray-700 leading-relaxed">{value}</p>
                    </div>
                  ))}
                </div>
              </div>

              {/* Engagement Patterns */}
              <div>
                <h3 className="text-lg font-semibold text-gray-900 mb-4">Engagement Patterns</h3>
                <div className="space-y-4">
                  {Object.entries(JSON.parse(userData.contentAnalysis).engagementPatterns).map(([key, value]) => (
                    <div key={key} className="space-y-2">
                      <h4 className="text-sm font-medium text-gray-600 capitalize">
                        {key.replace(/([A-Z])/g, ' $1').trim()}
                      </h4>
                      <p className="text-sm text-gray-700 leading-relaxed">{value}</p>
                    </div>
                  ))}
                </div>
              </div>

              {/* Expertise */}
              <div>
                <h3 className="text-lg font-semibold text-gray-900 mb-4">Expertise</h3>
                <div className="space-y-4">
                  {Object.entries(JSON.parse(userData.contentAnalysis).expertise).map(([key, value]) => (
                    <div key={key} className="space-y-2">
                      <h4 className="text-sm font-medium text-gray-600 capitalize">
                        {key.replace(/([A-Z])/g, ' $1').trim()}
                      </h4>
                      <p className="text-sm text-gray-700 leading-relaxed">{value}</p>
                    </div>
                  ))}
                </div>
              </div>

              {/* Community Interaction */}
              <div>
                <h3 className="text-lg font-semibold text-gray-900 mb-4">Community Interaction</h3>
                <div className="space-y-4">
                  {Object.entries(JSON.parse(userData.contentAnalysis).communityInteraction).map(([key, value]) => (
                    <div key={key} className="space-y-2">
                      <h4 className="text-sm font-medium text-gray-600 capitalize">
                        {key.replace(/([A-Z])/g, ' $1').trim()}
                      </h4>
                      <p className="text-sm text-gray-700 leading-relaxed">{value}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      )}

      {/* Sentiment Analysis */}
      {userData.sentiment && (
        <Card>
          <CardHeader>
            <CardTitle>Sentiment Analysis</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-2">
              {Object.entries(userData.sentiment).map(([key, value]) => (
                <div key={key} className="flex items-center gap-2">
                  <span className="w-24 capitalize">{key}</span>
                  <div className="w-full bg-gray-200 rounded-full h-2.5">
                    <div
                      className="bg-blue-600 h-2.5 rounded-full"
                      style={{ width: `${value}%` }}
                    />
                  </div>
                  <span className="w-12 text-right">{value}%</span>
                </div>
              ))}
            </div>
          </CardContent>
        </Card>
      )}

      {/* Activity Patterns */}
      {userData.engagement?.activityHeatmap && (
        <ActivityPatterns data={userData.engagement.activityHeatmap} />
      )}

      {/* Top Subreddits */}
      {userData.engagement?.topSubreddits && (
        <Card>
          <CardHeader>
            <CardTitle>Top Subreddits</CardTitle>
          </CardHeader>
          <CardContent>
            <TopSubreddits subreddits={userData.engagement.topSubreddits} />
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default ProfileAnalysis;