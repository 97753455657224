import { config } from '../config/config';

export const authService = {
  initiateAuth() {
    const state = Math.random().toString(36).substring(7);
    sessionStorage.setItem('oauth_state', state);
    window.location.href = `${config.apiUrl}${config.endpoints.auth}?state=${state}`;
  },

  async handleCallback(code) {
    const response = await fetch(`${config.apiUrl}${config.endpoints.callback}?code=${code}`);
    
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || 'Authentication failed');
    }

    const data = await response.json();
    sessionStorage.removeItem('oauth_state');
    this.saveUserData(data);
    return data;
  },

  saveUserData(data) {
    localStorage.setItem('redditUserData', JSON.stringify(data));
  },

  getUserData() {
    const data = localStorage.getItem('redditUserData');
    return data ? JSON.parse(data) : null;
  },

  clearUserData() {
    localStorage.removeItem('redditUserData');
  },

  isLoggedIn() {
    return !!this.getUserData();
  },

  async getInitialProfile(code) {
    const response = await fetch(`${config.apiUrl}/auth/reddit/profile`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ code })
    });

    if (!response.ok) {
      throw new Error('Failed to fetch initial profile');
    }

    return response.json();
  }
};