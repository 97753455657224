import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Card, CardHeader, CardTitle, CardContent } from './components/ui/card';
import { Button } from './components/ui/button';
import { Alert, AlertDescription } from './components/ui/alert';
import { RefreshCcw } from 'lucide-react';
import { Tabs, TabsList, TabsTrigger, TabsContent } from './components/ui/tabs';
import RedditOAuthCallback from './components/RedditOAuthCallback';
import ProfileAnalysis from './components/ProfileAnalysis';
import PersonalityAnalyzer from './components/PersonalityAnalyzer';
import { authService } from './services/authService';
import { api } from './services/api';

const MainContent = ({ 
  isLoggedIn, 
  loading, 
  userData, 
  error,
  usageLimits, 
  handleRedditLogin, 
  handleLogout,
  handleRefresh 
}) => (
  <div className="min-h-screen bg-gray-100 p-8">
    <div className="max-w-4xl mx-auto">
      <Card className="mb-8">
        <CardHeader className="flex justify-between items-center">
          <div>
            <CardTitle>Reddit Profile Analyzer</CardTitle>
            {usageLimits.dailyRemaining && (
              <div className="text-sm text-gray-500 mt-1">
                Analyses remaining today: {usageLimits.dailyRemaining} • This month: {usageLimits.monthlyRemaining}
              </div>
            )}
          </div>
          <div className="flex gap-2">
            {isLoggedIn && (
              <>
                <Button 
                  onClick={handleRefresh} 
                  variant="outline"
                  disabled={loading}
                  className="flex items-center gap-2"
                >
                  <RefreshCcw className={`h-4 w-4 ${loading ? 'animate-spin' : ''}`} />
                  {loading ? 'Refreshing...' : 'Refresh Analysis'}
                </Button>
                <Button onClick={handleLogout} variant="outline">
                  Logout
                </Button>
              </>
            )}
          </div>
        </CardHeader>
        <CardContent>
          {!isLoggedIn ? (
            <Button 
              onClick={handleRedditLogin}
              disabled={loading}
              className="w-full"
            >
              {loading ? 'Connecting...' : 'Connect Reddit Account'}
            </Button>
          ) : userData ? (
            <Tabs defaultValue="profile" className="space-y-6">
              <TabsList className="grid grid-cols-2 w-full">
                <TabsTrigger value="profile">Activity Analysis</TabsTrigger>
                <TabsTrigger value="personality">Personality Insights</TabsTrigger>
              </TabsList>

              <TabsContent value="profile" className="space-y-6">
                <ProfileAnalysis userData={userData} />
              </TabsContent>

              <TabsContent value="personality" className="space-y-6">
                <PersonalityAnalyzer userData={userData} />
              </TabsContent>
            </Tabs>
          ) : (
            <div className="text-center py-4 text-gray-500">
              {loading ? 'Analyzing your Reddit activity...' : 'Loading user data...'}
            </div>
          )}

          {error && (
            <Alert className="mt-4" variant={error.includes('Rate limit') ? 'warning' : 'destructive'}>
              <AlertDescription>
                {error}
                {error.includes('Rate limit') && (
                  <div className="mt-2 text-sm">
                    Please note: Analysis is limited to protect our services.
                    {error.includes('Daily limit') && ' You can try again tomorrow.'}
                    {error.includes('Monthly limit') && ' You can try again next month.'}
                  </div>
                )}
              </AlertDescription>
            </Alert>
          )}
        </CardContent>
      </Card>
    </div>
  </div>
);
const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [usageLimits, setUsageLimits] = useState({
    dailyRemaining: null,
    monthlyRemaining: null,
    rateReset: null
  });

  useEffect(() => {
    const storedData = authService.getUserData();
    if (storedData) {
      setUserData(storedData);
      setIsLoggedIn(true);
    }
  }, []);

  const handleRedditLogin = () => {
    setLoading(true);
    authService.initiateAuth();
  };

  const handleLogout = () => {
    authService.clearUserData();
    setUserData(null);
    setIsLoggedIn(false);
  };

  const handleRefresh = async () => {
    setLoading(true);
    setError(null);
    
    try {
      const data = await api.refreshAnalysis(userData.username);
      authService.saveUserData(data);
      setUserData(data);
    } catch (err) {
      setError(err.message);
      if (err.message.includes('Please try logging in again')) {
        handleLogout();
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Router>
      <Routes>
        <Route path="/auth/reddit/callback" element={
          <RedditOAuthCallback 
            setUserData={setUserData} 
            setIsLoggedIn={setIsLoggedIn} 
            setError={setError}
            setLoading={setLoading}
          />
        } />
        <Route 
          path="/" 
          element={
            <MainContent 
              isLoggedIn={isLoggedIn}
              loading={loading}
              userData={userData}
              error={error}
              usageLimits={usageLimits}
              handleRedditLogin={handleRedditLogin}
              handleLogout={handleLogout}
              handleRefresh={handleRefresh}
            />
          } 
        />
      </Routes>
    </Router>
  );
};

export default App;