import { config } from '../config/config';

export const api = {
async verifySession() {
  const response = await fetch(`${config.apiUrl}/analyze/debug`, {
      credentials: 'include'
  });
  return response.json();
},

  async refreshAnalysis(username) {
    const response = await fetch(`${config.apiUrl}${config.endpoints.refresh}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username })
    });
    
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || 'Failed to refresh analysis');
    }
    
    return response.json();
  },
  async analyzePersonality(username, userData) {
    const response = await fetch(`${config.apiUrl}${config.endpoints.personality}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, userData })
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || 'Failed to analyze personality');
    }

    return response.json();
  }
};
