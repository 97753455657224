export const config = {
    apiUrl: process.env.NODE_ENV === 'production' 
        ? 'https://reddmebackend-idljf8tlh-justin-wongs-projects-276e0fa3.vercel.app'
        : 'http://localhost:3001',
    endpoints: {
      auth: '/auth/reddit',
      callback: '/auth/reddit/callback',
      refresh: '/analyze/refresh',
      personality: '/analyze/personality'
    },
    rateLimit: {
      retryAfter: 60 * 1000, // 1 minute
      maxRetries: 3
    }
};
